<template>
  <div id="app" class="bg-gray-100">
    <main class="relative mx-auto max-w-7xl shadow-sm">
      <Navigation :show="isOpen"/>

      <router-view/>

      <footer class="pt-14 bg-gray-900">
        <div class="overflow-hidden py-12 px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <nav class="flex flex-wrap justify-center -my-2 -mx-5" aria-label="Footer">
            <div class="py-2 px-5">
              <router-link to="/" class="text-base text-gray-500 hover:text-gray-900">
                Home
              </router-link>
            </div>

            <div class="py-2 px-5">
              <router-link to="/montage-en-onderhoud" class="text-base text-gray-500 hover:text-gray-900">
                Montage & Onderhoud
              </router-link>
            </div>

            <div class="py-2 px-5">
              <router-link to="/machines-en-onderdelen" class="text-base text-gray-500 hover:text-gray-900">
                Machines & Onderdelen
              </router-link>
            </div>

            <div class="py-2 px-5">
              <router-link to="/lassen" class="text-base text-gray-500 hover:text-gray-900">
                Lassen
              </router-link>
            </div>

            <div class="py-2 px-5">
              <router-link to="/certificering" class="text-base text-gray-500 hover:text-gray-900">
                Certificering
              </router-link>
            </div>

            <div class="py-2 px-5">
              <router-link to="/contact" class="text-base text-gray-500 hover:text-gray-900">
                Contact
              </router-link>
            </div>
          </nav>
          <p class="mt-8 text-sm text-center text-gray-400">
            &copy; 2021 JC Las & Montagetechniek. Alle rechten voorbehouden.
          </p>
        </div>
      </footer>

    </main>
  </div>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800&family=Russo+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,500;0,600;0,700;1,400&family=Roboto+Condensed:wght@300&display=swap');

@layer base {
  #mobile-menu, p {
    @apply font-text;
  }

  h1, h2, h3 {
    @apply font-title;
  }
}
</style>
<script>

import Navigation from './components/Navigation';

export default {
  components : {
    Navigation
  },


  data : () => {
    return {
      isOpen : false
    }
  },

  watch : {
    $route() {
      this.isOpen = false;
    }
  }
}
</script>

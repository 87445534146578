import Vue from 'vue'
import App from './App.vue'
import router from './router'
import "tailwindcss/tailwind.css"
import Meta from 'vue-meta'

Vue.use(Meta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  refreshOnceOnNavigation: true
})

Vue.config.productionTip = false


new Vue({
  router,
  metaInfo: {
    titleTemplate: '%s | Specialist in las en montagetechniek',
  },
  render: h => h(App)
}).$mount('#app')

<template>
  <nav class="py-4 bg-gray-800">
    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div class="flex justify-between items-center h-16">
        <div class="flex items-center">
          <div class="flex-shrink-0" @click="$router.push('/').catch(err => {})">
            <img class="block w-auto h-20 my-2 lg:hidden" src="../../public/assets/logo_small.webp" width="200" height="90" alt="JC Las en Montagetechniek">
            <img class="hidden w-auto h-20 my-2 lg:block" src="../../public/assets/logo_normal.webp" width="140" height="64" alt="JC Las en Montagetechniek">
          </div>
          <div class="hidden sm:block sm:ml-6">
            <div class="flex space-x-4">
              <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
              <router-link to="/" class="py-2 px-3 font-normal text-white uppercase rounded-md hover:text-red-900 ">Home</router-link>
              <router-link to="/montage-en-onderhoud" class="py-2 px-3 font-normal text-white uppercase rounded-md hover:text-red-900 ">Montage & Onderhoud</router-link>
              <router-link to="/machines-en-onderdelen" class="py-2 px-3 font-normal text-white uppercase rounded-md hover:text-red-900 ">Machines & Onderdelen</router-link>
              <router-link to="/lassen" class="py-2 px-3 font-normal text-white uppercase rounded-md hover:text-red-900 ">Lassen</router-link>
              <router-link to="/certificering" class="py-2 px-3 font-normal text-white uppercase rounded-md hover:text-red-900 ">Certificeringen</router-link>
              <router-link to="/contact" class="py-2 px-3 font-normal text-white uppercase rounded-md hover:text-red-900 ">Contact</router-link>
            </div>
          </div>
        </div>
        <div class="flex -mr-2 sm:hidden">
          <!-- Mobile menu button -->
          <button @click="show = !show" type="button" class="inline-flex justify-center items-center p-2 text-gray-400 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
            <span class="sr-only">Open main menu</span>
            <!--
              Icon when menu is closed.

              Heroicon name: outline/menu

              Menu open: "hidden", Menu closed: "block"
            -->
            <svg class="block w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"/>
            </svg>
            <!--
              Icon when menu is open.

              Heroicon name: outline/x

              Menu open: "block", Menu closed: "hidden"
            -->
            <svg class="hidden w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
            </svg>
          </button>
        </div>
      </div>
    </div>

    <!-- Mobile menu, show/hide based on menu state. -->
    <div v-if="show" class="sm:hidden" id="mobile-menu">
      <div class="px-2 pt-2 pb-3 space-y-1">
        <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
        <router-link to="/" class="block py-2 px-3 text-lg font-thin text-gray-300 rounded-xs hover:bg-gray-700 hover:text-white">Home</router-link>
        <router-link to="/montage-en-onderhoud" class="block py-2 px-3 text-lg font-thin text-gray-300 rounded-xs hover:bg-gray-700 hover:text-white">Montage & Onderhoud</router-link>
        <router-link to="/machines-en-onderdelen" class="block py-2 px-3 text-lg font-thin text-gray-300 rounded-xs hover:bg-gray-700 hover:text-white">Machines & Onderdelen</router-link>
        <router-link to="/lassen" class="block py-2 px-3 text-lg font-thin text-gray-300 rounded-xs hover:bg-gray-700 hover:text-white">Lassen</router-link>
<!--        <router-link to="/certificering" class="block py-2 px-3 text-lg font-thin text-gray-300 rounded-xs hover:bg-gray-700 hover:text-white">Certificeringen</router-link>-->
        <router-link to="/contact" class="block py-2 px-3 text-lg font-thin text-gray-300 rounded-xs hover:bg-gray-700 hover:text-white">Contact</router-link>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name : "Navigation",

  props: ['show'],
}
</script>

<style scoped>

</style>
